export default {
  //mint
  post_uploadfile: '/history/uploadfile',
  post_uploadtext: '/history/uploadtext',
  post_minNewHistory:'/history/minNewHistory',
  post_allHistory: '/history/gettokenallbypage',
  post_buytoken: '/history/buytoken',
  post_subscribe: '/history/subscribe',
  //history
  post_historyDetails: '/history/gettokenbytokenid',
  post_followtoken: '/history/followtoken',
  //my
  post_history_searchList: '/history/gettokencount',
  post_historyList: '/history/gettokenbyadmin',
  post_setPricet: '/history/setprice',
  post_transfertoken: '/history/transfertoken',
  post_cancelSale: '/history/cancelsale',
  // historySeriesERC721
  post_uploadseriesnftpic:'/history/uploadseriesnftpic',
  post_createserires:'/history/createserires',
  // addround
  post_addround:'/history/addround',
  post_addroundList:'/history/getrounds',
  post_updateround:'/history/updateround', 
  // single upload
  post_createmetainfo:'/history/createmetainfo',
  post_getmetainfos:'/history/getmetainfos',
  post_deletemetainfos:'/history/deletemetainfos', 
  post_updateserires:'/history/updateserires',
  post_getsignature:'/history/getsignature',
  post_getseriesbaseurl:'/history/getseriesbaseurl',
  post_getmetacount:'/history/getmetacount',
  post_getseries:'/history/getseries', 
  post_getseriesinfos:'/history/getseriesinfos',
  post_getseriesnfts:'/history/getseriesnfts',
  post_getseriesnft:'/history/getseriesnft',
  post_getseriesnftsadmin:'/history/getseriesnftsadmin',
  post_transferseriestoken:'/history/transferseriestoken',
  post_setseriesprice:'/history/setseriesprice',
  post_cancelseriessale:'/history/cancelseriessale',
  post_buyseriestoken:'/history/buyseriestoken',
  post_genericnftinfo:'/history/genericnftinfo',
}
