export const tokenName = {
    1: "ETH",
    56: "BNB",
    42161: "ETH",
    324: 'ETH',
    280: 'ETH',
    66: 'OKT',
    // 421613: "AGOR",
}

export const address = {
    "Ethereum": {
        "HistoryERC721": "0x99f485ed6b65d294790189f6a14b344fDd87Ec0C",
        'HistoryMarket_address': '0x74e34E3274b1533510b850CBD1E91e8440387408',
    },
    "BNB Chain": {
        "HistoryERC721": "0x356588913bcCCAa3191a3833C70d0C103dd78e2A",
        'HistoryMarket_address': '0x35579EA4966658076e20152F9273b03c18C50050',
    },
    "ETH-Rinkby": {
        "HistoryERC721": "0x16DD0550cb00E8061D58e9cE4F0141AA4f0e012a",
        'HistoryMarket_address': '0x580EA0521F2212A1CF5e284a8Ac77b848752D994',

        "HistorySeriesERC721": "0x61935d0cF05510A3f848a8538058333f17eD0b63",
        // 'SeriesMinter01': "0x981862f2C95A134BdcD968cd4e2bdA581bDbe1ef",
        'SeriesMinter01': "0x1b8E915f41D2Cf9C8E32CFeE62eC552f83b6A472", //new

        'SeriesMinter01S': "0xE8aB675B9263972C72f94d9FedC4495f4DBEF6Da",
    },
    'Arbitrum': {
        "HistoryERC721": "0xc967Ac145E7AD2ad1ad5dbC26e3AA012F078e290",
        'HistoryMarket_address': '0xAdd3Fd71B68404A8A78DF9bF99B8012A783451B0',
    },
    'zkSync Era': {
        "HistoryERC721": "0x7B86F4120E193cCe16D5D07a66242B21055B2314",
        'HistoryMarket_address': '0x7D987bD87Da374B4D0Adc926581dD21953798C52',
    },
    'zkSync Era T': {
        "HistoryERC721": "0x658D1386475eDa8895A7CFEc5274c23c1FF6D1f5",
        'HistoryMarket_address': '0xd1F5BeBe9cDb956D3798267E943cda9703610f8A',
    },
    'OKXChain': {
        "HistoryERC721": "0x6b077C9B68cfF6EE9aDb07F9e97a0F53C4bc5cB2",
        'HistoryMarket_address': '0xf51f6956124717037057c5Cb6b3ecf167e86B82d',
    },
    // 'Arbitrum Goerli': {
    //     "HistoryERC721": "0xfddB8657074EA12190FE40352e4A157B75A83bBC",
    //     'HistoryMarket_address': '0xf07BeB9C4dAACfD1Fd483dDDB82546556E05bc56',
    // }
}

// 跟address名称保持一致
export const chainName = {
    97: "ETH-BscTestnet",
    4: "ETH-Rinkby",
    1: "Ethereum",
    56: "BNB Chain",
    42161: "Arbitrum",
    324: 'zkSync Era',
    280: 'zkSync Era T',
    66: 'OKXChain',
    // 421613: "Arbitrum Goerli"
}

export const chainImg = {
    1: require('@/assets/images/ethLogo.png'),
    56: require('@/assets/images/bnb.svg'),
    42161: require('@/assets/images/page-header/Arbitrum/ETH.png'),
    324: require('@/assets/images/page-header/zkSync/ETH.svg'),
    280: require('@/assets/images/page-header/zkSync/ETH.svg'),
    66: require('@/assets/images/page-header/OKT.svg'),
    // 421613: require('@/assets/images/page-header/Arbitrum/ETH.png'),
}

export const networkName = {
    1: "homestead",
    56: "BscMainnet",
    42161: "Arbitrum",
    324: 'zkSync Era',
    280: 'zkSync Era T',
    66: 'OKXChain',
    // 421613: "Arbitrum Goerli",
}

// Used when gas limit is not set and can not be estimated.
export const GAS_LIMIT_DEFAULT = 300000;




export const chainInfo = [{
    "chainId": "0x1",
    "chainName": "Ethereum",
    "rpcUrls": ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],


}, {
    "chainId": "0x38",
    "chainName": "Binance Smart Chain Mainnet",
    "rpcUrls": ["https://bsc-dataseed1.ninicoin.io"],


}, {
    "chainId": "0x89",
    "chainName": "Polygon Mainnet",
    "rpcUrls": ["https://polygon-rpc.com"],
    "blockExplorerUrls": ["https://polygonscan.com"],
}, {
    "chainId": "0xa4b1",
    "chainName": "Arbitrum",
    "rpcUrls": ["https://arb1.arbitrum.io/rpc"],
    "blockExplorerUrls": ["https://arbiscan.io/"],

},
{
    "chainId": "0x144",
    "chainName": "zkSync Era",
    "rpcUrls": ["https://mainnet.era.zksync.io"],
    "blockExplorerUrls": ["https://explorer.zksync.io"],

},
{
    "chainId": "0x118",
    "chainName": "zkSync Era T",
    "rpcUrls": ["https://zksync2-testnet.zksync.dev"],
    "blockExplorerUrls": ["https://goerli.explorer.zksync.io/"],

},
{
    "chainId": "0x42",
    "chainName": "OKXChain",
    "rpcUrls": ["https://exchainrpc.okex.org"],
    "blockExplorerUrls": ["https://www.oklink.com/okexchain"],

}
// {
//     "chainId": "0x66eed",
//     "chainName": "Arbitrum Goerli",
//     "rpcUrls": ["https://goerli-rollup.arbitrum.io/rpc"]
//     // "rpcUrls": ["https://endpoints.omniatech.io/v1/arbitrum/goerli/public"]

// },
]