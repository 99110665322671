import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from "./plugins/vuetify"
import store from './store'
import './tool/rem_size.js';
import './assets/css/index.scss';
import uploadFileQn from "./axios/upload_qn";
import api from "./axios/api";
import axios from "./axios/axios";
import './assets/css/swiper-3.4.2.min.css';
import 'font-awesome/css/font-awesome.min.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import 'hover.css'
import VueRellax from 'vue-rellax'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
Vue.use(VueRellax)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI,{locale});
Vue.prototype.$uploadFileQn = uploadFileQn;
Vue.prototype.$api = api;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false

// localStorage.setItem('baseUrl', 'https://api.blockhistory.io');
localStorage.setItem('baseUrl', 'https://aapi.historydao.io');
// localStorage.setItem('baseUrl', 'http://18.222.137.245:9081');

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
