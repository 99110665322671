import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        wallet_info: {
            address: '',
            network: '',
            balance: '',
            chainId: '',
        },
        okexChainId: null,
        loadding: false,
        walletName: '',
        initStatusComplete: false,
    },
    getters: {
        walletInfo: state => state.wallet_info,
        walletName: state => state.walletName,
        initStatusComplete: state => state.initStatusComplete,
        provider: (state) => {
            let provider = null;
            switch (state.walletName) {
                case 'OKX Wallet':
                    provider = (window.okxwallet || window.okexchain || window.ethereum);
                    break;
                case 'BitKeep':
                    provider = window.bitkeep && window.bitkeep.ethereum;
                    break;
                case 'MetaMask':
                    provider = window.ethereum;
                    break;
                default:
                    provider = window.ethereum;
            }
            if (!provider) {
                console.warn("please install injected wallet");
            }
            return provider;
        }
    },
    mutations: {
        setWalletInfo(state, value) {
            state.wallet_info = Object.assign({}, state.wallet_info, value)
        },
        setWalletName(state, value) {
            state.walletName = value ? value : ''
        },
        setInitStatusComplete(state) {
            state.initStatusComplete = true;
        },
        setOkexChainId(state, value) {
            state.okexChainId = value ? value : ''
        },
    }
})

export default store