<template>
    <div id="app">
        <Header @connectToWallet="connectToWallet" />
        <div class="content">
            <router-view ref="routerRef" v-if="isRouterAlive" />
        </div>
    </div>
</template>
<script>
import { setChainChangedCallback, setAccountsChangedCallback, setDisconnectCallback, setUpSigner, connectToWallet } from "./web3/common/wallet";
import { chainName } from "./web3/web3Config";
const wallet = require('./web3/common/wallet.js');

export default {
    name: 'App',
    provide() {
        return {
            reload: this.reload
        }
    },
    components: {
        'Header': () => import('./components/header.vue'),
    },
    data() {
        return {
            chainName: chainName,
            isRouterAlive: true,
        }
    },
    computed: {
        provider() {
            return this.$store.getters.provider;
        },
        walletName() {
            return this.$store.getters.walletName;
        }
    },
    created() {
        this.init();
    },
    mounted() {
        setTimeout(() => {
            // wait for the okexchain provider to load;
            (async () => {
                if (window.okexchain && !window.okxwallet) {
                    // The okxwallet actively switches the network to solve the problem that the correct chainid cannot be obtained;
                    let chainId = this.$store.state.okexChainId ? this.$store.state.okexChainId : '0x38';
                    chainId = '0x' + Number(chainId).toString(16);
                    try {
                        await window.okexchain.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: chainId }],
                        });
                    } catch (switchError) {
                        console.log(switchError);
                        // This error code indicates that the chain has not been added to OKX Wallet.
                    }
                }
            })();
        }, 1000)
        // this.initializationIntercom(); // 客服功能 intercom
    },
    methods: {
        connectToWallet(type) {
            this.$store.commit('setWalletName', type);
            if (type === 'OKX Wallet' && !this.provider) {
                // https://www.okx.com/cn/web3-docs/cn/extension/okx-multichain-api
                this.$message.warning('please install OKX Wallet');
                return;
            }
            if (type === 'BitKeep' && !this.provider) {
                // window.open("https://bitkeep.com/en/download?type=0&theme=light");
                this.$message.warning('please install BitKeep');
                return;
            }
            if (type === 'MetaMask' && (!this.provider || this.provider.isBitKeep)) {
                this.$message.warning('please install MetaMask');
                return;
            }
            connectToWallet(type).then(() => {
                this.setConnectedStateInfo();
                this.watchWalletconnectionStatus();
            }).catch(() => {
                console.error("Wallet connect failed.");
            });
        },
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function() {
                this.isRouterAlive = true
            })
        },
        setDisconnectedStateInfo() {
            this.$store.commit('setWalletName', '');

            this.$store.commit('setWalletInfo', {
                address: '',
                network: '',
                balance: '',
                chainId: '',
            });
            this.$store.commit('setInitStatusComplete');
        },
        setConnectedStateInfo() {
            wallet.getWalletInfo().then((info) => {
                this.$store.commit('setWalletInfo', info);
                this.$store.commit('setInitStatusComplete');
            });
        },
        setStateInfo(connectionStatus) {
            if (connectionStatus == 'disconnected') {
                this.setDisconnectedStateInfo();
            } else if (connectionStatus == 'connected') {
                setUpSigner(this.walletName);
                this.setConnectedStateInfo();
                this.watchWalletconnectionStatus();
            }
        },
        async getOKXWalletInfo() {
            console.log('try connect to OKX Wallet');

            this.$store.commit('setWalletName', 'OKX Wallet');
            if (this.provider) {
                let accounts = await this.provider.request({ method: 'eth_accounts' });
                if (accounts[0]) {
                    this.setStateInfo('connected');
                } else {
                    throw 'get OKX wallet info error'
                }
            } else {
                throw 'get OKX wallet info error'
            }
        },
        async getBitKeepWalletInfo() {
            console.log('try connect to bitkeep');

            this.$store.commit('setWalletName', 'BitKeep');
            if (this.provider) {
                let accounts = await this.provider.request({ method: 'eth_accounts' });
                if (accounts[0]) {
                    this.setStateInfo('connected');
                } else {
                    throw 'get BitKeep wallet info error'
                }
            } else {
                throw 'get BitKeep wallet info error'
            }
        },
        async getMetaMaskWalletInfo() {
            console.log('try connect to metamask');
            this.$store.commit('setWalletName', 'MetaMask');
            if (this.provider) {
                let accounts = await this.provider.request({ method: 'eth_accounts' });
                if (accounts[0]) {
                    this.setStateInfo('connected');
                } else {
                    this.setStateInfo('disconnected');
                }
            } else {
                this.setStateInfo('disconnected');
                console.warn("please install injected wallet");
            }
        },
        async init() {
            // try {
                await this.getBitKeepWalletInfo().catch(async (e)=> {
                    console.log(e);
                    await this.getOKXWalletInfo().catch(async (e) => {
                        console.log(e);
                        await this.getMetaMaskWalletInfo()
                    })
                });
            // } catch (e) {
            //     console.log(e);
            //     this.getMetaMaskWalletInfo();
            // }
        },
        watchWalletconnectionStatus() {
            if (this.provider) {
                this.provider.removeAllListeners();
            }
            setChainChangedCallback(() => {
                window.location.reload();
            }, this.provider);
            setAccountsChangedCallback((accounts) => {
                if (accounts[0]) {
                    this.setConnectedStateInfo();
                } else {
                    this.setDisconnectedStateInfo();
                }

            }, this.provider);
            setDisconnectCallback(() => {
                this.setDisconnectedStateInfo();
            }, this.provider);

        },
        //intercom
        initializationIntercom() {
            // Add this to the window.intercomSettings object in your code:
            window.intercomSettings = {
                app_id: "hezx3u0j",
                email: 'kuanhai0710@outlook.com',
            };
            (function() {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                } else {
                    var d = document;
                    var i = function() { i.c(arguments); };
                    i.q = [];
                    i.c = function(args) { i.q.push(args); };
                    w.Intercom = i;
                    var l = function() {
                        var s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/d3gh4rmg';
                        var x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                    };
                    if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); }
                }
            })();
        }
    }
}
</script>
<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Bold;
    src: url('./assets/fonts/font3.woff2') format('woff2');
}

@font-face {
    font-family: Poppins-Regular;
    src: url('./assets/fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url('./assets/fonts/Poppins-SemiBold.woff') format('woff');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('./assets/fonts/Poppins-Medium.woff') format('woff');
}

* {
    margin: 0;
    padding: 0;
}

#app,
html,
body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

#app {

    // display: flex;
    // flex-direction: column;
    .content {
        // flex: 1;
        // overflow: hidden;
    }
}

@media (max-width: 1125px) {
    #app>.content {
        width: 100%;
        overflow: hidden;
    }
}
</style>