import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
        path: '/',
        redirect: '/homePage',
    },
    {
        path: '/homePage',
        name: 'homePage',
        component: () => import("../view/homePage/index.vue"),
    },
    {
        path: '/market',
        name: 'market',
        component: () => import("../view/market/index.vue"),
    },
    {
        path: '/mint',
        name: 'mint',
        component: () => import("../view/mint/index.vue"),
    },
    {
        path: '/history',
        name: 'history',
        component: () => import("../view/history/index.vue"),
    },
    {
        path: '/myNFTs',
        name: 'myNFTs',
        component: () => import("../view/myNFTs/index.vue"),
    },
    {
        path: '/transaction',
        name: 'transaction',
        component: () => import("../view/myNFTs/transaction.vue"),
    },
    /* {
         path: '/child',
         name: 'child',
         component: () => import("../components/HelloWorld.vue"),
     },*/
    {
        path: '/series',
        name: 'series',
        component: () => import("../view/series/index.vue"),
    },
    {
        path: '/uploadMint',
        name: 'uploadMint',
        component: () => import("../view/uploadMint/index.vue"),
    },
    {
        path: '/addRounds',
        name: 'addRounds',
        component: () => import("../view/series/addRounds/index.vue"),
    },
    {
        path: '/add',
        name: 'add',
        component: () => import("../view/series/add/index.vue"),
    },
    {
        path: '/uploadList',
        name: 'uploadList',
        component: () => import("../view/uploadList/index.vue"),
    },
    {
        path: '/uploadNFTs',
        name: 'uploadNFTs',
        component: () => import("../view/uploadList/uploadNFTs/index.vue"),
    },
    {
        path: '/selectTheNft',
        name: 'selectTheNft',
        component: () => import("../view/selectTheNft/index.vue"),
    },
    {
        path: '/mediaLinks',
        name: 'mediaLinks',
        component: () => import("../view/mediaLinks/index.vue"),
    },
    {
        path: '/modifyUploadMint',
        name: 'modifyUploadMint',
        component: () => import("../view/modifyUploadMint/index.vue"),
    },
]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router